import { Box, SimpleGrid, VStack, Text, Badge, Button, IconButton, HStack, Tooltip } from "@chakra-ui/react";
import { useState } from "react";
import { Tenant } from "../../../__generated__/resolvers-types";
import { EditIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { PackOptions, LanguagesOptions } from "../constants";
import { format } from "date-fns";
import { useDisclosure } from "@chakra-ui/react";
import EditTenantModal from "../../../components/modals/edit";

interface TenantMasonryProps {
  tenants: Tenant[];
}

const TenantMasonry = ({ tenants }: TenantMasonryProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null);

  const handleAdminLogin = (tenant: Tenant) => {
    window.open(
      `${process.env.REACT_APP_WEB_URL}/index.php?tenant_id=${tenant?.id}&user=${tenant?.admin}&type=admin`,
      "_blank"
    );
  };

  const handleWorkerLogin = (tenant: Tenant) => {
    window.open(
      `${process.env.REACT_APP_WEB_URL}/index.php?tenant_id=${tenant?.id}&user=${tenant?.worker}&type=worker`,
      "_blank"
    );
  };

  const handleEdit = (tenant: Tenant) => {
    setSelectedTenant(tenant);
    onOpen();
  };

  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
      {tenants.map((tenant) => (
        <Box
          key={tenant.id}
          bg="white"
          p={6}
          rounded="lg"
          shadow="sm"
          borderWidth="1px"
          borderColor={tenant.valid ? "green.100" : "red.100"}
          position="relative"
          transition="all 0.2s"
          _hover={{ transform: "translateY(-2px)", shadow: "md" }}
        >
          <VStack align="stretch" spacing={4}>
            {/* Header */}
            <HStack justify="space-between">
              <Badge
                colorScheme={tenant.valid ? "green" : "red"}
                px={2}
                py={1}
                borderRadius="full"
              >
                {tenant.valid ? "Live" : "Inactive"}
              </Badge>
              <Text fontSize="sm" color="gray.500">
                {tenant.createdAt ? format(new Date(tenant.createdAt), "MMM d, yyyy") : "-"}
              </Text>
            </HStack>

            {/* Main Content */}
            <VStack align="stretch" spacing={2}>
              <Text fontWeight="bold" fontSize="lg">{tenant.name}</Text>
              <Text color="gray.600">{tenant.domaine}</Text>
              <HStack spacing={2}>
                <Badge colorScheme="blue">
                  {PackOptions.find((pack) => pack.value === tenant.pack)?.label}
                </Badge>
                <Badge colorScheme="purple">
                  {LanguagesOptions.find((lg) => lg.value === tenant.language)?.label}
                </Badge>
              </HStack>
            </VStack>

            {/* User Info */}
            {tenant.valid && (
              <VStack align="stretch" spacing={2}>
                <HStack>
                  <Text fontSize="sm" color="gray.500">Admin:</Text>
                  <Badge colorScheme="blue" variant="subtle">{tenant.admin}</Badge>
                </HStack>
                <HStack>
                  <Text fontSize="sm" color="gray.500">Worker:</Text>
                  <Badge colorScheme="purple" variant="subtle">{tenant.worker}</Badge>
                </HStack>
              </VStack>
            )}

            {/* Actions */}
            {tenant.valid && (
              <HStack spacing={2} mt={2}>
                <Button
                  size="sm"
                  rightIcon={<ExternalLinkIcon />}
                  onClick={() => handleAdminLogin(tenant)}
                  flex={1}
                  colorScheme="blue"
                  variant="outline"
                >
                  Admin
                </Button>
                <Button
                  size="sm"
                  rightIcon={<ExternalLinkIcon />}
                  onClick={() => handleWorkerLogin(tenant)}
                  flex={1}
                  variant="outline"
                >
                  Worker
                </Button>
                <IconButton
                  aria-label="Edit tenant"
                  icon={<EditIcon />}
                  size="sm"
                  onClick={() => handleEdit(tenant)}
                  variant="outline"
                />
              </HStack>
            )}
          </VStack>
        </Box>
      ))}
      <EditTenantModal isOpen={isOpen} onClose={onClose} tenant={selectedTenant || undefined} />
    </SimpleGrid>
  );
};

export default TenantMasonry;
