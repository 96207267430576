import { 
  Button, Flex, HStack, Image, useDisclosure, Text, Box, 
  Spinner, Input, InputGroup, InputLeftElement,
  Menu, MenuButton, MenuList, MenuItem, Badge,
  Stack, Grid, VStack, Icon, ButtonGroup
} from "@chakra-ui/react";
import { RiDashboardLine, RiLogoutBoxLine, RiLayoutGridLine, RiTableLine, RiLayoutMasonryLine } from 'react-icons/ri';
import { Navigate, useLocation } from "react-router-dom";
import { NetworkStatus, useQuery } from "@apollo/client";
import { Tenant } from "../../__generated__/resolvers-types";
import CreateTenantModal from "../../components/modals/create";
import useQueries from "./queries";
import { AddIcon, SearchIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { PackOptions, LanguagesOptions } from "./constants";
import TenantCard from "./card/index";
import { useState, useMemo } from "react";
import TenantTable from "./table";
import TenantMasonry from "./masonry";
import EditTenantModal from "../../components/modals/edit";

export type ColumnNames<T> = Exclude<keyof T, "__typename">[];

type SortField = "name" | "createdAt" | "pack";
type SortOrder = "asc" | "desc";
type ViewType = "cards" | "table" | "masonry";

const ListScreen = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortField, setSortField] = useState<SortField>("createdAt");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const [packFilter, setPackFilter] = useState("");
  const [languageFilter, setLanguageFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [viewType, setViewType] = useState<ViewType>("table");
  // Attributes
  const { GetTenants } = useQueries();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { state } = useLocation();
  const prefix = state?.prefix;

  // Queries
  const { data, loading, networkStatus } = useQuery<{
    tenants: Tenant[];
  }>(GetTenants, {
    variables: {
      prefix,
    },
    pollInterval: 2000,
  });

  const filteredAndSortedTenants = useMemo(() => {
    if (!data?.tenants) return [];
    
    let filtered = data.tenants.filter(tenant => {
      const matchesSearch = (tenant.name?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
        (tenant.domaine?.toLowerCase() || "").includes(searchQuery.toLowerCase());
      
      const matchesPack = !packFilter || tenant.pack === Number(packFilter);
      const matchesLanguage = !languageFilter || tenant.language === Number(languageFilter);
      const matchesStatus = !statusFilter || 
        (statusFilter === "active" && tenant.valid) || 
        (statusFilter === "inactive" && !tenant.valid);

      return matchesSearch && matchesPack && matchesLanguage && matchesStatus;
    });

    return filtered.sort((a, b) => {
      const aValue = a[sortField] || "";
      const bValue = b[sortField] || "";
      
      if (sortOrder === "asc") {
        return String(aValue).localeCompare(String(bValue));
      } else {
        return String(bValue).localeCompare(String(aValue));
      }
    });
  }, [data?.tenants, searchQuery, sortField, sortOrder, packFilter, languageFilter, statusFilter]);

  if (!prefix) {
    return <Navigate to="/" replace={true} />;
  }
  
  const handleLogout = () => {
    window.location.href = "/";
  }

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  // Render
  return (
    <>
      <Flex>
        {/* Sidebar */}
        <Box
          w="64"
          bg="white"
          borderRight="1px"
          borderColor="#E5E7EB"
          h="100vh"
          position="fixed"
          left={0}
          top={0}
        >
          <VStack p={4} h="full" align="stretch" justify="space-between">
            <VStack align="stretch" spacing={8}>
              <HStack spacing={3} px={2}>
                <Image src="vert-logo.png" height="8" />
              </HStack>
              
              <Button
                variant="ghost"
                justifyContent="flex-start"
                leftIcon={<Icon as={RiDashboardLine} boxSize={5} />}
                py={3}
                bg="#F3F4F6"
              >
                Dashboard
              </Button>
            </VStack>

            <Button
              variant="ghost"
              justifyContent="flex-start"
              leftIcon={<Icon as={RiLogoutBoxLine} boxSize={5} />}
              py={3}
              color="#991B1B"
              _hover={{ bg: '#FEE2E2' }}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </VStack>
        </Box>

        {/* Main Content */}
        <Box flex={1} ml="64">
          {/* Header */}
          <Box 
            bg="white" 
            w="full" 
            borderBottomWidth={1}
            borderColor="#E5E7EB"
            position="sticky"
            top={0}
            zIndex={10}
          >
            {/* Title and Actions */}
            <Box px={8} py={4} borderBottomWidth={1} borderColor="#E5E7EB">
              <Flex justify="space-between" align="center" w="100%">
                <Text fontSize="xl" fontWeight="semibold" color="#111827">
                  Tenant Management
                </Text>
                <HStack spacing={4} flexShrink={0}>
                  <ButtonGroup size="md" isAttached variant="outline">
                    <Button
                      leftIcon={<Icon as={RiLayoutGridLine} />}
                      onClick={() => setViewType("cards")}
                      colorScheme={viewType === "cards" ? "blue" : "gray"}
                      variant={viewType === "cards" ? "solid" : "outline"}
                    >
                      Cards
                    </Button>
                    <Button
                      leftIcon={<Icon as={RiTableLine} />}
                      onClick={() => setViewType("table")}
                      colorScheme={viewType === "table" ? "blue" : "gray"}
                      variant={viewType === "table" ? "solid" : "outline"}
                    >
                      Table
                    </Button>
                    <Button
                      leftIcon={<Icon as={RiLayoutMasonryLine} />}
                      onClick={() => setViewType("masonry")}
                      colorScheme={viewType === "masonry" ? "blue" : "gray"}
                      variant={viewType === "masonry" ? "solid" : "outline"}
                    >
                      Masonry
                    </Button>
                  </ButtonGroup>
                  <Button 
                    leftIcon={<AddIcon />} 
                    bg="#3B82F6"
                    color="white"
                    onClick={onOpen}
                    _hover={{ bg: '#2563EB' }}
                    size="md"
                  >
                    Create new tenant
                  </Button>
                </HStack>
              </Flex>
            </Box>

            {/* Filters */}
            <Box px={8} py={4} bg="white">
              <Flex gap={4} wrap="wrap">
                {/* Search */}
                <InputGroup w="64">
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.400" />
                  </InputLeftElement>
                  <Input
                    placeholder="Search tenants..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    bg="white"
                    borderColor="#E5E7EB"
                  />
                </InputGroup>
                {/* Filters */}
                <HStack spacing={4} flex={1}>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      variant="outline"
                      borderColor="#E5E7EB"
                      bg="white"
                    >
                      Pack: {packFilter ? PackOptions.find(p => p.value === Number(packFilter))?.label : 'All'}
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => setPackFilter("")}>All Packs</MenuItem>
                      {PackOptions.map((option: { value: number; label: string }) => (
                        <MenuItem 
                          key={option.value} 
                          onClick={() => setPackFilter(String(option.value))}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>

                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      variant="outline"
                      borderColor="#E5E7EB"
                      bg="white"
                    >
                      Language: {languageFilter ? LanguagesOptions.find(l => l.value === Number(languageFilter))?.label : 'All'}
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => setLanguageFilter("")}>All Languages</MenuItem>
                      {LanguagesOptions.map((option: { value: number; label: string }) => (
                        <MenuItem 
                          key={option.value} 
                          onClick={() => setLanguageFilter(String(option.value))}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>

                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      variant="outline"
                      borderColor="#E5E7EB"
                      bg="white"
                    >
                      Status: {statusFilter ? statusFilter.charAt(0).toUpperCase() + statusFilter.slice(1) : 'All'}
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => setStatusFilter("")}>All Status</MenuItem>
                      <MenuItem onClick={() => setStatusFilter("active")}>Active</MenuItem>
                      <MenuItem onClick={() => setStatusFilter("inactive")}>Inactive</MenuItem>
                    </MenuList>
                  </Menu>

                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      variant="outline"
                      borderColor="#E5E7EB"
                      bg="white"
                    >
                      Sort by: {sortField}
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => handleSort("name")}>Name</MenuItem>
                      <MenuItem onClick={() => handleSort("createdAt")}>Created At</MenuItem>
                      <MenuItem onClick={() => handleSort("pack")}>Pack</MenuItem>
                    </MenuList>
                  </Menu>
                </HStack>

                <HStack>
                  <Badge bg="#DCFCE7" color="#166534" px={2} py={0.5} borderRadius="full" fontSize="sm">
                    {filteredAndSortedTenants.filter(t => t.valid).length} ACTIVE
                  </Badge>
                  <Badge bg="#FEE2E2" color="#991B1B" px={2} py={0.5} borderRadius="full" fontSize="sm">
                    {filteredAndSortedTenants.filter(t => !t.valid).length} INACTIVE
                  </Badge>
                </HStack>
              </Flex>
            </Box>
          </Box>

          {/* Content */}
          <Box 
            px={8} 
            py={8} 
            bg="#F8F9FC" 
            minH="calc(100vh - 160px)"
            maxW="calc(100vw - 275px)"
            css={{
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#F1F1F1',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#C1C1C1',
                borderRadius: '4px',
                '&:hover': {
                  background: '#A1A1A1',
                },
              },
            }}
          >
            <Stack spacing={6} overflowX={"auto"}>
              {loading && networkStatus !== NetworkStatus.refetch ? (
                <Flex justify="center" py={12}>
                  <Spinner color="#3B82F6" />
                </Flex>
              ) : filteredAndSortedTenants.length > 0 ? (
                <>
                  {viewType === "cards" && (
                    <Grid 
                      templateColumns="repeat(auto-fill, minmax(400px, 1fr))" 
                      gap={6}
                    >
                      {filteredAndSortedTenants.map((tenant, index) => (
                        <TenantCard key={index} tenant={tenant} />
                      ))}
                    </Grid>
                  )}
                  {viewType === "table" && (
                    <TenantTable tenants={filteredAndSortedTenants} />
                  )}
                  {viewType === "masonry" && (
                    <TenantMasonry tenants={filteredAndSortedTenants} />
                  )}
                </>
              ) : (
                <Flex 
                  justify="center" 
                  py={12}
                >
                  <Text color="gray.500">No tenants found</Text>
                </Flex>
              )}
            </Stack>
          </Box>
        </Box>
      </Flex>
      <EditTenantModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default ListScreen;
