import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Text,
  Badge,
  useDisclosure,
  Stack,
} from "@chakra-ui/react";
import { EditIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import type { Tenant } from "../../../__generated__/resolvers-types";
import { format } from "date-fns";
import EditTenantModal from "../../../components/modals/edit";
import { useApolloClient, useMutation } from "@apollo/client";
import useQueries from "../queries";
import { LanguagesOptions, PackOptions } from "../constants";

interface TenantCardProps {
  tenant: Tenant;
}

const TenantCard = ({ tenant }: TenantCardProps) => {
  // Attributes
  const { isOpen: isEditModalOpen, onOpen: onOpenEditModal, onClose: onCloseEditModal } = useDisclosure();
  const { DeleteTenant } = useQueries();
  const client = useApolloClient();

  // Mutations
  const [deleteTenant] = useMutation(DeleteTenant);

  // Handlers
  const handleAdminLogin = () => {
    if (tenant.id && tenant.admin) {
      window.open(
        `${process.env.REACT_APP_WEB_URL}/index.php?tenant_id=${tenant.id}&user=${tenant.admin}&type=admin`,
        "_blank"
      );
    }
  };

  const handleWorkerLogin = () => {
    if (tenant.id && tenant.worker) {
      window.open(
        `${process.env.REACT_APP_WEB_URL}/index.php?tenant_id=${tenant.id}&user=${tenant.worker}&type=worker`,
        "_blank"
      );
    }
  };

  const handleDelete = () => {
    if (tenant.id) {
      deleteTenant({
        variables: { input: { id: tenant.id } },
        onCompleted: () => {
          client.refetchQueries({
            include: ["getTenants"],
          });
        },
      });
    }
  };

  return (
    <Box
      bg="white"
      borderWidth={1}
      borderColor="#E5E7EB"
      borderRadius="lg"
      overflow="hidden"
      position="relative"
    >
      <Box p={6}>
        <Stack spacing={6}>
          <Flex justify="space-between" align="center">
            <Stack spacing={1}>
              <Flex align="center" gap={2}>
                <Text fontSize="lg" fontWeight="medium" color="#111827">
                  {tenant.name || "-"}
                </Text>
                {!tenant.valid && (
                  <Badge bg="#FEE2E2" color="#991B1B" fontSize="xs" px={2} py={0.5} borderRadius="full">
                    Inactive
                  </Badge>
                )}
              </Flex>
              <Text fontSize="sm" color="#6B7280">
                {tenant.domaine || "-"}
              </Text>
            </Stack>
            <IconButton
              icon={<EditIcon />}
              aria-label="Edit tenant"
              variant="ghost"
              size="sm"
              onClick={onOpenEditModal}
            />
          </Flex>

          <Stack spacing={3}>
            <HStack justify="space-between">
              <Text fontSize="sm" color="#6B7280">Pack</Text>
              <Text fontSize="sm" fontWeight="medium">
                {PackOptions.find((pack) => pack.value === tenant.pack)?.label || "-"}
              </Text>
            </HStack>
            <HStack justify="space-between">
              <Text fontSize="sm" color="#6B7280">Language</Text>
              <Text fontSize="sm" fontWeight="medium">
                {LanguagesOptions.find((lg) => lg.value === tenant.language)?.label || "-"}
              </Text>
            </HStack>
            <HStack justify="space-between">
              <Text fontSize="sm" color="#6B7280">Created</Text>
              <Text fontSize="sm" fontWeight="medium">
                {tenant.valid && tenant.createdAt ? format(tenant.createdAt, "yyyy-MM-dd HH:mm") : "-"}
              </Text>
            </HStack>

            {tenant.valid && (
              <>
                <HStack justify="space-between">
                  <Text fontSize="sm" color="#6B7280">Admin User</Text>
                  <Badge bg="#EFF6FF" color="#1E40AF" px={2} py={0.5} borderRadius="md">
                    {tenant.admin}
                  </Badge>
                </HStack>
                <HStack justify="space-between">
                  <Text fontSize="sm" color="#6B7280">Worker User</Text>
                  <Badge bg="#F5F3FF" color="#5B21B6" px={2} py={0.5} borderRadius="md">
                    {tenant.worker}
                  </Badge>
                </HStack>
              </>
            )}
          </Stack>

          {tenant.valid && (
            <HStack spacing={2}>
              <Button
                rightIcon={<ExternalLinkIcon />}
                variant="outline"
                size="sm"
                flex={1}
                onClick={handleAdminLogin}
                borderColor="#E5E7EB"
              >
                Admin Login
              </Button>
              <Button
                rightIcon={<ExternalLinkIcon />}
                variant="outline"
                size="sm"
                flex={1}
                onClick={handleWorkerLogin}
                borderColor="#E5E7EB"
              >
                Worker Login
              </Button>
            </HStack>
          )}
        </Stack>
      </Box>

      <EditTenantModal isOpen={isEditModalOpen} onClose={onCloseEditModal} tenant={tenant} />
    </Box>
  );
};

export default TenantCard;
