import { Table, Thead, Tbody, Tr, Th, Box } from "@chakra-ui/react";
import { Tenant } from "../../../__generated__/resolvers-types";
import TenantRow from "../row";
import { ColumnNames } from "..";

interface TenantTableProps {
  tenants: Tenant[];
}

const TenantTable = ({ tenants }: TenantTableProps) => {
  const columns: ColumnNames<Tenant> = ["name", "domaine", "createdAt", "pack", "language"];

  return (
    <Box 
      bg="white" 
      rounded="lg" 
      shadow="sm"
      overflowX="auto"
      css={{
        '&::-webkit-scrollbar': {
          height: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#F1F1F1',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#C1C1C1',
          borderRadius: '4px',
          '&:hover': {
            background: '#A1A1A1',
          },
        },
      }}
    >
      <Box minW="1500px" w="100%">
        <Table w="100%" layout="fixed">
        <Thead 
          position="sticky" 
          top={0} 
          bg="white" 
          zIndex={1} 
          boxShadow="sm"
        >
          <Tr>
            <Th w="100px">Status</Th>
            {columns.map((column) => (
              <Th key={column} w={column === "name" || column === "domaine" ? "200px" : "150px"}>
                {column}
              </Th>
            ))}
            <Th w="120px">Admin</Th>
            <Th w="120px">Worker</Th>
            <Th w="150px">Admin Login</Th>
            <Th w="150px">Worker Login</Th>
            <Th w="80px">Edit</Th>
            <Th w="80px">Delete</Th>
          </Tr>
        </Thead>
        <Tbody>
          {tenants.map((tenant, index) => (
            <TenantRow key={index} tenant={tenant} columns={columns} />
          ))}
        </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default TenantTable;
