import { Button, IconButton, Td, Tr, useDisclosure, Badge, HStack, Tooltip, Box } from "@chakra-ui/react";
import { ITenantRowProps } from "./props";
import { EditIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Tenant } from "../../../__generated__/resolvers-types";
import { LanguagesOptions, PackOptions } from "../constants";
import { format } from "date-fns";
import EditTenantModal from "../../../components/modals/edit";
import { useApolloClient, useMutation } from "@apollo/client";
import useQueries from "../queries";

const TenantRow = ({ tenant, columns }: ITenantRowProps) => {
	// Attributes
	const { isOpen: isEditModalOpen, onOpen: onOpenEditModal, onClose: onCloseEditModal } = useDisclosure();
	const { DeleteTenant } = useQueries();
	const client = useApolloClient();

	// Mutations
	const [deleteTenant] = useMutation(DeleteTenant);

	const renderCell = (columnName: keyof Tenant, data: any) => {
		switch (columnName) {
			case "createdAt":
				return tenant.valid ? format(data, "yyyy-MM-dd HH:mm") : "-";
			case "pack":
				return PackOptions.find((pack) => pack.value === data)?.label;
			case "language":
				return LanguagesOptions.find((lg) => lg.value === data)?.label;
			default:
				return data;
		}
	};

	// Handlers
	const handleAdminLogin = (tenant: Tenant) => {
		window.open(
			// @ts-ignore
			`${process.env.REACT_APP_WEB_URL}/index.php?tenant_id=${tenant?.id}&user=${tenant?.admin}&type=admin`,
			"_blank"
		);
	};

	const handleWorkerLogin = (tenant: Tenant) => {
		window.open(
			// @ts-ignore
			`${process.env.REACT_APP_WEB_URL}/index.php?tenant_id=${tenant?.id}&user=${tenant?.worker}&type=worker`,
			"_blank"
		);
	};

	const handleDelete = () => {
		deleteTenant({
			variables: { input: { id: tenant?.id } },
			onCompleted: () => {
				client.refetchQueries({
					include: ["getTenants"],
				});
			},
		});
	};

	// Render
	return (
		<Tr>
			<Td>
				<Badge
					colorScheme={tenant.valid ? "green" : "red"}
					fontSize="sm"
					px={3}
					py={1}
					borderRadius="full"
					textTransform="capitalize"
					display="inline-flex"
					alignItems="center"
					gap={2}
				>
					{tenant.valid ? (
						<>
							<Box as="span" w={2} h={2} borderRadius="full" bg="success.500" />
							Live
						</>
					) : (
						<>
							<Box as="span" w={2} h={2} borderRadius="full" bg="error.500" />
							Inactive
						</>
					)}
				</Badge>
			</Td>
			{columns.map((column, colIndex) => (
				<Td key={colIndex}>
					<Tooltip 
						label={renderCell(column, tenant[column])} 
						placement="top" 
						hasArrow
						openDelay={500}
					>
						<Box>
							{renderCell(column, tenant[column])}
						</Box>
					</Tooltip>
				</Td>
			))}

			{tenant.valid ? (
				<Td>
					<Tooltip label={tenant.admin} placement="top" hasArrow>
						<Badge colorScheme="blue" variant="subtle" px={2}>
							{tenant.admin}
						</Badge>
					</Tooltip>
				</Td>
			) : (
				<Td>-</Td>
			)}
			{tenant.valid ? (
				<Td>
					<Tooltip label={tenant.worker} placement="top" hasArrow>
						<Badge colorScheme="purple" variant="subtle" px={2}>
							{tenant.worker}
						</Badge>
					</Tooltip>
				</Td>
			) : (
				<Td>-</Td>
			)}
			<Td>
				{tenant.valid ? (
					<Button
						rightIcon={<ExternalLinkIcon />}
						variant={"secondary"}
						colorScheme="blue"
						size="sm"
						w="full"
						onClick={() => handleAdminLogin(tenant)}
						_hover={{
							transform: 'translateY(-1px)',
							boxShadow: 'sm',
						}}
						transition="all 0.2s"
					>
						Admin Login
					</Button>
				) : (
					"-"
				)}
			</Td>
			<Td>
				{tenant.valid ? (
					<Button
						rightIcon={<ExternalLinkIcon />}
						variant={"secondary"}
						size="sm"
						w="full"
						onClick={() => handleWorkerLogin(tenant)}
						_hover={{
							transform: 'translateY(-1px)',
							boxShadow: 'sm',
						}}
						transition="all 0.2s"
					>
						Worker Login
					</Button>
				) : (
					"-"
				)}
			</Td>
			<Td>
				{tenant.valid ? (
					<IconButton
						onClick={onOpenEditModal}
						variant={"secondary"}
						aria-label="edit"
						icon={<EditIcon />}
						size="sm"
						_hover={{
							transform: 'translateY(-1px)',
							boxShadow: 'sm',
							bg: 'primary.50'
						}}
						transition="all 0.2s"
					/>
				) : (
					"-"
				)}
			</Td>
			<EditTenantModal isOpen={isEditModalOpen} onClose={onCloseEditModal} tenant={tenant} />
			<Td>
				<Tooltip label="Delete tenant" placement="left" hasArrow>
					<IconButton
						aria-label="Delete tenant"
						icon={
							<svg width="16" height="16" viewBox="0 0 90 90" fill="currentColor">
								<path d="M 76.777 2.881 H 57.333 V 2.412 C 57.333 1.08 56.253 0 54.921 0 H 35.079 c -1.332 0 -2.412 1.08 -2.412 2.412 v 0.469 H 13.223 c -1.332 0 -2.412 1.08 -2.412 2.412 v 9.526 c 0 1.332 1.08 2.412 2.412 2.412 h 63.554 c 1.332 0 2.412 -1.08 2.412 -2.412 V 5.293 C 79.189 3.961 78.109 2.881 76.777 2.881 z" />
								<path d="M 73.153 22.119 H 16.847 c -1.332 0 -2.412 1.08 -2.412 2.412 v 63.057 c 0 1.332 1.08 2.412 2.412 2.412 h 56.306 c 1.332 0 2.412 -1.08 2.412 -2.412 V 24.531 C 75.565 23.199 74.485 22.119 73.153 22.119 z M 33.543 81.32 c 0 1.332 -1.08 2.412 -2.412 2.412 h -2.245 c -1.332 0 -2.412 -1.08 -2.412 -2.412 V 30.799 c 0 -1.332 1.08 -2.412 2.412 -2.412 h 2.245 c 1.332 0 2.412 1.08 2.412 2.412 V 81.32 z M 48.535 81.32 c 0 1.332 -1.08 2.412 -2.412 2.412 h -2.245 c -1.332 0 -2.412 -1.08 -2.412 -2.412 V 30.799 c 0 -1.332 1.08 -2.412 2.412 -2.412 h 2.245 c 1.332 0 2.412 1.08 2.412 2.412 V 81.32 z M 63.526 81.32 c 0 1.332 -1.08 2.412 -2.412 2.412 h -2.245 c -1.332 0 -2.412 -1.08 -2.412 -2.412 V 30.799 c 0 -1.332 1.08 -2.412 2.412 -2.412 h 2.245 c 1.332 0 2.412 1.08 2.412 2.412 V 81.32 z" />
							</svg>
						}
						variant="ghost"
						colorScheme="red"
						size="sm"
						onClick={handleDelete}
						_hover={{
							transform: 'translateY(-1px)',
							boxShadow: 'sm',
							bg: 'danger.50'
						}}
						transition="all 0.2s"
					/>
				</Tooltip>
			</Td>
		</Tr>
	);
};

export default TenantRow;
